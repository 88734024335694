import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { FilterData } from "@/core/types/gws-agent/dashboard/FilterData";
import { DashboardWrapper } from "@/core/types/gws-agent/dashboard/DashboardWrapper";
import { ListItem } from "@/core/types/gws-agent/dashboard/ListItem";
import { DashboardAgentEnum } from "@/store/enums/DashboardAgentEnums";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";
import { formatInternationalDate } from "@/core/helpers/date-format";
const authStore = useAuthenticationStore();

export const useDashboardJobFairStore = defineStore({
  id: "dashboardJobFairStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateTotalQuota: {
        JOB_FAIR: {
          total_quota: 0,
          availbale_quota: 0,
        },
        P3MI: {
          total_quota: 0,
          availbale_quota: 0,
        },
        MEDICAL: {
          total_quota: 0,
          availbale_quota: 0,
        },
        BLK: {
          total_quota: 0,
          availbale_quota: 0,
        },
      },
      stateFilterData: {
        date: [new Date(), new Date()],
      } as FilterData,
      stateDisplayData: [] as ListItem[],
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
    displayTotalQuota(state) {
      return state.stateTotalQuota;
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      const [startDate, endDate] = this.stateFilterData.date;
      const params = {
        start_date: formatInternationalDate(startDate.toString()),
        end_date: formatInternationalDate(endDate.toString()),
        id: authStore?.user?.org_id || "",
        organization_type: authStore?.user?.org_type || "",
      };
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<
          ApiResponse<DashboardWrapper<ListItem[]>>
        > = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `dashboards/job_seeker?${new URLSearchParams(params).toString()}`
        );
        const { job_seekers } = response.data.data;

        const newArray = job_seekers.map((item) => {
          const key = Object.keys(item)[0];
          const subItem = item[key];
          return {
            name: DashboardAgentEnum[key],
            ...subItem,
          };
        });

        this.stateDisplayData = newArray;
      } catch (error) {
        console.log(error);
      }

      try {
        const responseJobSeekers: AxiosResponse<ApiResponse<any>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `dashboards/total_quota`
          );

        const { total_quota } = responseJobSeekers.data.data || {};

        this.stateTotalQuota = total_quota;
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
  },
});
