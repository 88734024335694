<template>
  <div
    class="card rounded-xl m-4 h-100"
    :style="{ 'background-color': secondColor }"
  >
    <div class="row g-0 align-items-center justify-content-center h-100">
      <div class="col-md-4 d-flex align-items-center justify-content-center">
        <div
          class="rounded-circle-container"
          :style="{ 'background-color': color }"
        >
          <i
            class="bi bi-bar-chart-fill h4 bg-white rounded-1 p-1 m-0"
            :style="{ color: color }"
          ></i>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card-body p-0">
          <h5 class="card-title">{{ title }}</h5>
          <p class="card-text">{{ desc }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "CardColor",
  props: {
    title: String,
    desc: String,
    color: String,
    secondColor: String,
  },
  components: {},
});
</script>
<style lang="scss" scoped>
.rounded-circle-container {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
